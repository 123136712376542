import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';
import { sanitizer, useCSSMotion, useSectionTimeSpent } from '../../util';

import './List.scss';

const List = ({ data }) => {
  const { title, description, listItems, name, id } = data;
  const containerRef = useRef(null);
  const listMotionState = useCSSMotion(containerRef);

  useSectionTimeSpent(name, containerRef);

  return (
    <div id={id} className={classnames('List', listMotionState)} ref={containerRef}>
      <h2 className="List-title">{title}</h2>
      <div
        className="List-description blog-style"
        dangerouslySetInnerHTML={{ __html: sanitizer(description, true, {}) }}
      />
      <ul className="List-items">
        {listItems?.map((item, index) => {
          return (
            <li key={index} className="ListItem">
              <div className="ListItem-icon">
                <GatsbyImage
                  className="icon"
                  fluid={item.icon?.localFile?.childImageSharp.fluid}
                  alt={item.icon?.altText}
                />
              </div>
              <p className="ListItem-text">{item.text}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;

export const fragments = graphql`
  fragment List on WpPage_Flexiblelayout_FlexibleChildren_List {
    title
    description
    listItems {
      icon {
        ...Icon
      }
      text
    }
    id
    jumpToLinkTitle
    name
  }

  fragment List_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_List {
    title
    description
    listItems {
      icon {
        ...Icon
      }
      text
    }
    id
    jumpToLinkTitle
    name
  }
`;
